import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, Link, HeadProps } from 'gatsby'
import { Layout, BlockRenderer, HeaderText, Footer, HeadTags } from '@components'

interface PageProps {
  data: Queries.IndexPageQuery
}

const IndexPage = ({ data: { sanityPage } }: PageProps) => {
  if (!sanityPage) return <></>
  return (
    <Layout>
      <ContentWrapper>
        <Content>
          {sanityPage?.copy?.map((copySet: Partial<Queries.SanityCopySet> | Partial<Queries.SanityImage> | null, i) =>
            copySet?._type === 'copySet' ? (
              <CopySet key={`copyset-${i}`}>
                <Copy>
                  <HeaderText>{(copySet as Partial<Queries.SanityCopySet>).title}</HeaderText>
                  <BlockRenderer>{(copySet as Partial<Queries.SanityCopySet>)._rawCopy!}</BlockRenderer>
                </Copy>
              </CopySet>
            ) : copySet?._type === 'image' ? (
              <Image src={(copySet as Partial<Queries.SanityImage>).asset?.url || ''} key={`image-${i}`} />
            ) : (
              <React.Fragment key={`null-${i}`}></React.Fragment>
            ),
          )}
        </Content>
      </ContentWrapper>
      <Footer />
    </Layout>
  )
}

const ContentWrapper = styled.div`
  position: relative;
  z-index: 10;
  padding: 20px;
  min-height: calc(100vh - 112px);
  min-height: 100dvh;
  @media only screen and (min-width: 744px) {
    min-height: calc(100vh - 104px);
    padding: 40px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background: rgba(77, 170, 233, 1);
  background: color(display-p3 0.3 0.67 0.91);
  color: white;
  line-height: 1.8em;
`

const Content = styled.article`
  position: relative;
  z-index: 10;
  padding-bottom: 60px;
  @media only screen and (min-width: 744px) {
    column-count: 2;
    column-gap: 40px;
    padding-bottom: 0;
  }
  @media only screen and (min-width: 1200px) {
    column-count: 3;
    max-width: 1200px;
  }
`

const CopySet = styled.div`
  break-inside: avoid;
  padding-bottom: 1em;
`

const Copy = styled.section``

const Image = styled.img`
  max-width: 100%;
  border-radius: 12px;
`

export const query = graphql`
  query IndexPage {
    sanityPage(slug: { current: { eq: "index" } }) {
      title
      copy {
        ... on SanityCopySet {
          _rawCopy
          title
          _type
        }
      }
    }
  }
`

export const Head = (props: HeadProps) => <HeadTags {...props} />

export default IndexPage
